<script setup lang="ts">
</script>

<template>
  <div class="min-h-screen w-full">
    <div class="relative h-screen">
      <!-- Page Content -->
      <div class="">
        <div class="">
          <main class="">
            <NuxtPage />
          </main>
        </div>
      </div>
    </div>

    <div class="fixed bottom-4 right-4">
      <ColorModeToggle />
    </div>
  </div>
</template>
